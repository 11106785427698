import * as React from 'react';
import { Fab } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


import Form from './Form';
import About from './About';

const infoStyle = {
    position: 'fixed',
    marginLeft: '15px',
    marginTop: '15px',
    zIndex: '3'
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Info(props) {
    const [form, setForm] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        props.setInfoOpen(true);
    };

    const handleClose = () => {
        props.setInfoOpen(false);
    };

    const handleFormOpen = () => {
        setForm(true);
    };

    const handleFormClose = () => {
        setForm(false);
    };

    const uploadButton =
        <Button
            variant="outlined"
            onClick={handleFormOpen}
            size="large"
        >
            Upload Your Hum
        </Button>

    const thanksMessage =
        <Typography color="primary">Thank you for your hum!</Typography>


    return (
        <div>
            <Fab
                aria-label='info'
                color="primary"
                onClick={handleClickOpen}
                style={infoStyle}
            >
                <InfoOutlinedIcon />
            </Fab>
            <Dialog
                fullScreen
                open={props.infoOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Container>
                    <Grid
                        container
                        item
                        justifyContent='center'
                        rowSpacing={4}
                    >
                        <Grid container item xs={12}>
                            <About />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            md={12}
                            justifyContent='center'
                        >
                            {props.myHum ? thanksMessage : uploadButton}
                        </Grid>
                        <Grid container item xs={12}
                            justifyContent='center'
                        >
                            <Typography
                                variant="body1">
                                We recommend that you use Google Chrome or Firefox browsers for the best experience.<br/>
                                If you run into any technical issues email:{" "}
                                <Link href="mailto:ms.tinastefanou@gmail.com">ms.tinastefanou@gmail.com</Link>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item xs={12}
                            justifyContent='center'
                        >
                            <Typography
                                variant="caption"
                            >
                                <em>This project was made possible by the Australian Government's Regional Arts Fund, which supports the arts in regional and remote Australia.</em>
                            </Typography>
                        </Grid>
                        <Dialog
                            open={form}
                            onClose={handleFormClose}
                            fullScreen={fullScreen}
                        >
                            <Form
                                handleMyHum={props.handleMyHum}
                                handleMyLocation={props.handleMyLocation}
                                handleFormClose={handleFormClose}
                                handleClose={handleClose} />
                        </Dialog>
                    </Grid>
                </Container>
            </Dialog>
        </div>
    )

}