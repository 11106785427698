import React, { useState, useEffect, useRef } from 'react';
import HumRep from './humRep';

export default function HumCanvas(props) {
  // is animation updating
  const [ticking, setTicking] = useState(false);
  // touch start pos // needs a ref
  const [touchYStartPos, setTouchYStartPos] = useState(0);
  var touchYRef = useRef(touchYStartPos);
  // current position global offset // needs a ref
  const [humPos, setHumPos] = useState(0);
  var humPosRef = useRef(humPos);
  // animation speed
  const animationSpeed = 0.01;
  const [humDeets, setHumDeets] = useState([[]]);
  const [humOffsetArray, sethumOffsetArray] = useState([[1,1]]);

  // update ref on value update
  useEffect(() => {touchYRef.current = touchYStartPos}, [touchYStartPos]);
  useEffect(() => {humPosRef.current = humPos}, [humPos]);

  // set up eventlisters on load for wheel and touch - do I also want to include key scroll?
  useEffect(() => {
    // if on mobile add touch else add wheel

    // this isn't working on desktop check on mobile proper
      //mobile
      // detect where the initial touch's Y position is
      document.addEventListener('touchstart', function(event){
        setTouchYStartPos(event.touches[0].pageY);
      });
      //scroll on touch move
      document.addEventListener('touchmove', function(event) {
        if (!ticking) {
          window.requestAnimationFrame(function() {
            //console.log(event.touches[0].pageY);
            // check if scrolling up or down
            // new touchYStartPos isn't update because of blank array - can I detect this in the event?
            if(event.touches[0].pageY > touchYRef.current){
              setHumPos(p => p - 0.1);
            } else {
              setHumPos(p => p + 0.1);
            }
            setTicking(false);
          });    
          setTicking(true);
        }
      }, { passive: true });

      //desktop
      document.addEventListener('wheel', function(event) {
        if (!ticking) {
          window.requestAnimationFrame(function() {
            // check if scrolling up or down
            if(event.wheelDelta > 0){
              setHumPos(p => p - animationSpeed);
            } else {
              setHumPos(p => p + animationSpeed);
            }
            setTicking(false);
          });    
          setTicking(true);
        }
      }, { passive: true });

    function rotateHums(/* rotationModifier */){
      setHumPos(p => p + 0.001);
      window.requestAnimationFrame(rotateHums);
    }

    window.requestAnimationFrame(rotateHums);
    
  }, []);

  /* update hum deets */
  // load new hum into the arrays
  useEffect(() => {
    // make sure hums array isn't empty
    if(props.hums.length > 0){
    // get the old arrays
    let lengthUpdateArray = humDeets;
    let oldHumOffsetArray = humOffsetArray;
    // determine initial offset
    let offset = (props.hums.length-1)/3.127;
    // get long lat offset
    let lat = props.hums[props.hums.length-1].worldpos.latitude * offset;
    let long = props.hums[props.hums.length-1].worldpos.longitude * offset;
    // add new values to end
    // the 40 and 10 represent bringing the width/height into 80%
    lengthUpdateArray.push([
      ((Math.sin(humPosRef.current - lat)+1)*40)+10,
      ((Math.sin(long)+1)*40)+10,
      (Math.cos(humPosRef.current - offset)+1)
    ]);
    // add to offset array    
    oldHumOffsetArray.push([
      lat,
      long
    ]);
    // update the arrays
    setHumDeets(lengthUpdateArray);    
    sethumOffsetArray(humOffsetArray);
    } 
  }, [props.hums]);

  /* scroll animation when animation driver updates */
  useEffect(() => {
    incrementScroll(humPos, humDeets, setHumDeets, humOffsetArray);
  }, [humPos]);

  const humArray = props.hums.map((hum, index) => 
    <HumRep
      key={index}
      humNum={index}
      isolateHum={props.isolateHum}
      leftOffset={humDeets[index][0]}
      topOffset={humDeets[index][1]}
      fontSize={humDeets[index][2]}
      humText={hum.humText} />
  );
  
  const lineArray = props.hums.filter((hum, index) => index > 0).map((hum, index) =>
    
    <path key={index}
          d={`M ${humDeets[index][0]} ${humDeets[index][1]}
              Q ${humDeets[index+1][0]} ${humDeets[index][1]}        
                ${humDeets[index+1][0]} ${humDeets[index+1][1]}        
              `} />
  );

    return (
      <div id="bgWrapper">
        {humArray}
        <div id="humLineWrapper">
          <svg width="100%" height="100%" viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              id="svgBox"
              stroke="black"
              strokeWidth="0.1"
              fill="transparent"
              preserveAspectRatio="none"
              >
          {lineArray}
          </svg>
        </div>
      </div>
    )
}

function incrementScroll(posInput, humDeets, setHumDeets, humOffsetArray){
  let newHumDeets = [];
  for(let i = 0; i < humDeets.length; i++){
    let offset = (i-1)/3.127;
    newHumDeets.push(
      // the 40 and 10 represent bringing the width/height into 80%
      [((Math.sin(posInput - humOffsetArray[i][0])+1)*40)+10,
        ((Math.sin(humOffsetArray[i][1])+1)*40)+10,
       (Math.cos(posInput - offset)+1)]
    );
  }
  setHumDeets(newHumDeets);
}