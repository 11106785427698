import React, { useEffect } from 'react';

export default function HumRep(props) {
  
  useEffect(() => {
    document.getElementById("humDiv"+props.humNum).addEventListener("click", props.isolateHum);
    if(props.humNum === 20){
      document.getElementById("humDiv"+props.humNum).classList.add("MyHum");
    }
  }, []);

  return (
    <div className="hum-rep-wrapper" style={{left: props.leftOffset + "%", top: props.topOffset + "%", fontSize: props.fontSize + "em"}}>
      <p id={"humDiv"+props.humNum} className="hum-rep" >{props.humText}</p>
    </div>
  )  
}
