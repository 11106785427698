import * as React from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

import Info from './components/Info';
import Country from './components/Country';
import HumPlayers from './components/Tone';

let theme = createTheme({
  palette: {
    primary: {
      main: '#65b031',
    },
    secondary: {
      main: '#8a8a8a',
    },
  },
});

theme = responsiveFontSizes(theme);

const rootStyle = {
  position: 'absolute',
  display: 'inline-block',
  height: '100vh',
  width: '100vw',
  //is this what you mean by to-do number 4?
  overflow: 'hidden',
}

function App() {
  const [country, setCountry] = React.useState(true);
  const [myHum, setMyHum] = React.useState(null);
  const [myLocation, setMyLocation] = React.useState({longitude: 0, latitude: 0});
  const [infoOpen, setInfoOpen] = React.useState(false);

  const handleCountryClose = () => {
    setCountry(false);
  }

  // function to trigger isolation
  function handleMyHum(myHum){
    setMyHum(myHum);
  }
  function handleMyLocation(myLocation){
    setMyLocation(myLocation);
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={rootStyle}>
        {/* {canvas} */}
        {/* <Audio /> */}
        <Info handleMyHum={handleMyHum} handleMyLocation={handleMyLocation} myHum={myHum} infoOpen={infoOpen} setInfoOpen={setInfoOpen}/>
        <Country country={country} handleCountryClose={handleCountryClose} />
        {!country &&
         <HumPlayers myHum={myHum} myLocation={myLocation} infoOpen={infoOpen} />
         }
         {/* ////////////////////////// */}
         {/* this was to test the mute on open/close function, would you mind creating a useState bool and sending it to the infoOpen prop on the hum players component */}
         {/* <Button onClick={() => {setHums(!hums)}} style={{ float: 'right', zIndex: 10 }}>isolate</Button> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
