import React, { Component } from 'react';
import * as Tone from 'tone';
import * as StartAudioContext from 'startaudiocontext';
import HumCanvas from './humCanvas';

/* function frac(f) {
  return f % 1;
} */

function rand() {
  return Math.round(Math.random() * 80)+10;
}

/* function hash( x ) {
  return frac(Math.sin(x)*43758.5453);
}  */

const svgns = "http://www.w3.org/2000/svg";

const limiter = new Tone.Limiter(-3).toDestination();
const masterGain = new Tone.Gain(1).connect(limiter);

export class HumPlayers extends Component {
  constructor() {
    super()
    this.state = {
      randomArray: [],
      lineArray: [],
      playerArray: [],
      audioRefArray: [],
      divArray: [],
      lastAudioLoaded: 0,
      scrollCounter: 0,
      allAudioDBLoaded: false,
      isolated: false,
      ticking: false,

      limiter: new Tone.Limiter(-3).toDestination()
    }
    this.recursiveAudioLoad = this.recursiveAudioLoad.bind(this);
    this.isolateHum = this.isolateHum.bind(this);
    this.unIsolateHum = this.unIsolateHum.bind(this);
    this.addMyHum = this.addMyHum.bind(this);
    this.drawSVGLines = this.drawSVGLines.bind(this);
    this.rollTheDice = this.rollTheDice.bind(this);
  }

  getDivStyle(ranX, ranY){
    return `position:absolute; left: ${ranX}%; top: ${ranY}%; transform: translate(-50%, -50%); cursor:pointer`
  }

  render() {
    return (
      <>
        {/* <Button onClick={this.isolateHum.bind(this)} style={{ float: 'right', zIndex: 10 }}>isolate</Button>  */}
        {/* ////////////////////////// */}
        {/* Had to get rid of this Container - it was doing some weird auto margin stuff that I didn't know how to get rid of - happy if you want to switch these divs to Containers if you can retain their style */}
        {/* <div id="humDivWrapper" /> */}
        {/* ////////////////////////// */}
        {/* I added an onclick function to each div so it isolates which ever one you've clicked */}
        {/* <div id="humLineWrapper">
          <svg width="100%" height="100%" viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              id="svgBox"
              preserveAspectRatio="none"
              >
          </svg>
        </div> */}
        <HumCanvas
          hums={this.state.divArray}
          players={this.state.playerArray}
          isolateHum={this.isolateHum}
           />
      </>
    )
  }

  componentDidMount() {
    
    
    // handle scroll wheel event
    /* window.addEventListener('wheel', this.handleScroll); */
    // should handle touch scroll ??
    /* window.addEventListener('touchmove', this.handleScroll); */
    StartAudioContext(Tone.context, '#AOCclose').then(() => this.rollTheDice());
  }
  // if props change check for either file uploaded or info window state
  componentDidUpdate(prevProps) {
    if (prevProps.myHum !== this.props.myHum) {
      this.addMyHum(this.props.myHum);
    }
    // this is a little janky but should work
    if (prevProps.infoOpen !== this.props.infoOpen) {
      // if true mute all else reset volume
      if(this.props.infoOpen){
        masterGain.gain.value = 0;
      } else {
        masterGain.gain.value = 1;
      }
    }
  }
  rollTheDice(){
    let randomArrayProto = [];
    for (let i = 0; i < 21; i++){
      let thisRandom = [rand(), rand()];
      randomArrayProto.push(thisRandom);
    }
    this.setState({randomArray: randomArrayProto});
    // now start audio process by fetching the hum references
    this.get20Hums();
  }
  // get 20 hums from the db
  get20Hums() {
    fetch(`https://thelongesthum.world/api/gethums`, {
      headers: {
        'Content-type': 'application/json'
      },
      method: 'GET'
    })
      .then(res => res.json())
      .then(res => this.recursiveAudioLoad(res));
  }

  recursiveAudioLoad(refArray) {
    // load reference array into state if changed
    this.setState({ audioRefArray: refArray });   
    let player = new Tone.Player("https://humstore.thelongesthum.world/" + refArray[this.state.lastAudioLoaded].fileName, () => {
      // on load if loop needs to continue
      if (this.state.lastAudioLoaded < refArray.length - 1) {
        this.setState(prevState => ({
          lastAudioLoaded: prevState.lastAudioLoaded + 1
        }));
        this.recursiveAudioLoad(refArray);
      } else {
        this.setState({ lastAudioLoaded: 0, allAudioDBLoaded: true})
      }
    }).connect(masterGain);
    // how many days old is the hum - currently unused - didn't know what relationship to playback speed you might want
    let daysOld = this.calculateDaysBetween(refArray[this.state.lastAudioLoaded].date);
    // set playback speed - 7670 days in 21 years
    player.playbackRate = 1 - (daysOld / 7670);
    // play as soon as the buffer is loaded and loop
    player.loop = true;
    player.autostart = true;
    if(this.state.isolated){
      player.volume.value = -18;
    }
    // create the div
    // let humDiv = document.createElement('div');
    // set id
    //humDiv.id = 'humDiv' + (this.state.lastAudioLoaded + 1);
    // assign inner text
    //humDiv.innerHTML = 'longitude: ' + refArray[this.state.lastAudioLoaded].worldpos.longitude + ' latitude: ' + refArray[this.state.lastAudioLoaded].worldpos.latitude;
    let humDiv = refArray[this.state.lastAudioLoaded];
    humDiv.humText = `${humDiv.worldpos.longitude}, ${humDiv.worldpos.latitude}`;
    /* let humDiv = 'longitude: ' + refArray[this.state.lastAudioLoaded].worldpos.longitude + ' latitude: ' + refArray[this.state.lastAudioLoaded].worldpos.latitude; */
    // add the click function to isolate the hum
    //humDiv.onclick = e => {this.isolateHum(e)};
    //style
    //humDiv.style = this.getDivStyle(this.state.randomArray[this.state.lastAudioLoaded][0], this.state.randomArray[this.state.lastAudioLoaded][1]);
    // stick to wrapper
    //document.getElementById('humDivWrapper').appendChild(humDiv);
    // add direct reference to state
    this.setState(prevState => ({
      playerArray: [...prevState.playerArray, player],
      divArray: [...prevState.divArray, humDiv],
    }));
    console.log(this.state.divArray);
    // now lets make the svg lines if not the first point
    if(this.state.lastAudioLoaded !== 0){
      //this.drawSVGLines();
    }
  }
  drawSVGLines(){
    let svgBox = document.getElementById('svgBox');
    // remove old lines
    while (svgBox.firstChild) {
      svgBox.removeChild(svgBox.lastChild);
    }
    let linesNum = 0;
    // work out how many lines to render
    if(!this.state.allAudioDBLoaded){
      if(this.state.lastAudioLoaded !== 0){
        linesNum = this.state.playerArray.length;
      }
    } else {
      linesNum = this.state.playerArray.length;
    }
    let lineArray = [];
    for (let i = 0; i < this.state.divArray.length; i++){
      let thisDiv = this.state.divArray[i];
      thisDiv.style = this.getDivStyle(this.state.randomArray[i][0], this.state.randomArray[i][1]);
    }
    for (let i = 0; i < linesNum; i++){      
      let thisLine = document.createElementNS(svgns, "line");
      if(i === linesNum){
        thisLine.setAttribute("x1", this.state.randomArray[i][0]);
        thisLine.setAttribute("x2", this.state.randomArray[0][0]);
        thisLine.setAttribute("y1", this.state.randomArray[i][1]);
        thisLine.setAttribute("y2", this.state.randomArray[0][1]);
      } else {
        thisLine.setAttribute("x1", this.state.randomArray[i][0]);
        thisLine.setAttribute("x2", this.state.randomArray[i+1][0]);
        thisLine.setAttribute("y1", this.state.randomArray[i][1]);
        thisLine.setAttribute("y2", this.state.randomArray[i+1][1]);
      }
      thisLine.setAttribute("stroke", "black");
      thisLine.setAttribute("stroke-width", "0.1");
      thisLine.classList.add("humLines");
      svgBox.appendChild(thisLine);
      lineArray.push(thisLine);
    }
    this.setState({lineArray: lineArray});    
  }
  handleScroll = () => {
    if (!this.state.ticking) {
      window.requestAnimationFrame(() => {
        this.scrollSVGLines();
        this.setState({ticking: false});
      });  
      this.setState({ticking: true});
    }
  };
  scrollSVGLines(){
    // move scrolltimer up
    /* this.setState(prevState => ({
      scrollCounter: prevState.scrollCounter + 0.1
    }));
    // fetch ref to old random pos
    let newRandomArray = this.state.randomArray;
    // add new random pos based on sin of scrolltimer
    for (let i = 0; i < newRandomArray.length; i++){
      for (let k = 0; k < 2; k++){
        let oldValue = newRandomArray[i][k];
        newRandomArray[i][k] = oldValue + Math.sin(this.state.scrollCounter)*hash(i/1.4643);
      }
      this.setState({randomArray: newRandomArray});
    }
    this.drawSVGLines(); */
  }
  calculateDaysBetween(dateInput) {
    let humDate = new Date(dateInput);
    return (Date.now() - humDate) / (1000 * 3600 * 24)
  }
  addMyHum(myHum){
    let player = new Tone.Player(URL.createObjectURL(myHum)).connect(masterGain);
    player.loop = true;
    player.autostart = true;
    // enter new hum in sequence if < 21 hums or replace 21st hum
    let oldArray = this.state.playerArray.slice(0,19);
    let newArray = oldArray;
    newArray.push(player);
    this.setState({
      playerArray: newArray
    });
    // create the div
    /* let humDiv = document.createElement('div');
    // set id
    humDiv.id = 'humDiv21';
    // assign inner text
    humDiv.innerHTML = 'you are here';
    // add the click function to isolate the hum
    humDiv.addEventListener("click", e => {this.isolateHum(e)});
    //style
    humDiv.style = this.getDivStyle(this.state.randomArray[20][0], this.state.randomArray[20][1]);
    //make it green
    humDiv.classList.add("MyHum");
    // stick to wrapper
    document.getElementById('humDivWrapper').appendChild(humDiv); */
    let humDiv = {
      worldpos: {
        latitude: 80,
        longitude: 80
      },
      humText: "you are here"
    };
    this.setState(prevState => ({
      divArray: [...prevState.divArray, humDiv]
    }));
  }
  isolateHum(e){
    let humArrayNum = parseInt(e.target.id.replace("humDiv",""));
    console.log(humArrayNum)
    let newArray = [];
    if (!this.state.isolated){
      e.target.classList.add("IsoHum");
      for (let i = 0; i < this.state.playerArray.length; i++){
        let thisPlayer = this.state.playerArray[i];
        if(i === humArrayNum){
          thisPlayer.volume.value = 0;
          newArray.push(thisPlayer);
        } else {
          thisPlayer.volume.value = -18;
          newArray.push(thisPlayer);
        }
      }
      this.setState({isolated: true, playerArray: newArray, lastHumIsolated: humArrayNum});
    } else {
      //check for same div clicked
      if(humArrayNum !== this.state.lastHumIsolated){
        document.getElementsByClassName("IsoHum")[0].classList.remove("IsoHum");
        e.target.classList.add("IsoHum");
        for (let i = 0; i < this.state.playerArray.length; i++){
          let thisPlayer = this.state.playerArray[i];
          if(i === humArrayNum){
            thisPlayer.volume.value = 0;
            newArray.push(thisPlayer);
          } else {
            thisPlayer.volume.value = -18;
            newArray.push(thisPlayer);
          }
        }
        this.setState({isolated: true, playerArray: newArray, lastHumIsolated: humArrayNum});
      } else {
        e.target.classList.remove("IsoHum");
        for (let i = 0; i < this.state.playerArray.length; i++){
          let thisPlayer = this.state.playerArray[i];
          thisPlayer.volume.value = 0;
          newArray.push(thisPlayer);
        }
        this.setState({isolated: false, playerArray: newArray});
      }      
    }   
  }
  unIsolateHum(){
    console.log('canv')
    let newArray = [];
    for (let i = 0; i < this.state.playerArray.length; i++){
      let thisPlayer = this.state.playerArray[i];
      thisPlayer.volume.value = 0;
      newArray.push(thisPlayer);
    }
    this.setState({isolated: false, playerArray: newArray});   
  }
}

export default HumPlayers
