import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CountrySelect from './CountrySelect';

export default function Form(props) {
    // const [formValues, setFormValues] = useState(defaultValues);
    const [postcode, setPostcode] = useState(null);
    const [hum, setHum] = useState(null);
    const [country, setCountry] = useState("null");
    // const [coordinates, setCoordinates] = useState({ longitude: -33.0245311, latitude: 115.6605265 })

    const handleSubmit = (event) => {
        event.preventDefault();
        const myHeaders = new Headers();
        const formdata = new FormData();
        formdata.append("upload", hum);
        formdata.append("postcode", postcode);
        formdata.append("country", country);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://thelongesthum.world/api/uploadhum", requestOptions)
            .then(response => response.text())
            // Imagine it will be something like this  
            //.then(result=>setCoordinates(result.worldpos))
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        // props.handleMyLocation(coordinates);
        props.handleMyHum(hum);

        props.handleClose()
    }

    const isSubmitButtonDisabled = !(
        hum &&
        postcode
    )


    return (<>
        <DialogTitle>Upload Hum</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Upload your hum using the form below
            </DialogContentText>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Button
                    variant="outlined"
                    component="label"
                    margin="normal"
                    size="large"
                >
                    Upload Hum
                    <input
                        type="file"
                        accept=".mp3,.m4a,audio/*"
                        onChange={(e) => setHum(e.target.files[0])}
                        hidden
                    />
                </Button>
                <FormHelperText id="component-helper-text">
                    {hum ? hum.name : '*no file selected'}
                </FormHelperText>
                <TextField
                    required
                    id="postcode-input"
                    name="postcode"
                    label="Your postcode"
                    type="text"
                    margin="normal"
                    onChange={(e) => setPostcode(e.target.value)}
                >

                </TextField>
                <CountrySelect country={country} setCountry={setCountry} />
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleFormClose}>Cancel</Button>
            <Button
                type="submit"
                disabled={isSubmitButtonDisabled}
                onClick={handleSubmit}
            >Upload</Button>
        </DialogActions>
    </>
    )

}