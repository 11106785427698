
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';

export default function About() {
    return (
        <>
            <Grid
                container
                rowSpacing={3}
            >
                <Grid container item>
                    <Typography
                        variant="h2">
                        The Longest Hum
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <Typography
                        variant="body1">
                        The Longest Hum is an interactive voice work by Tina Stefanou (with Patrick Hase and Alisa Blakeney). It is sown together with voices, codes, locations, stringy bits and a desire to create the longest humming action in the Universe.                </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sm={4}
                    justifyContent="center"
                >
                    <Typography
                        variant="body1"><em>
                            Put your hums into it<br /><br />

                            The Longest Hum is…<br />
                            a digital river system<br />
                            a sketch-becoming<br />
                            a living time capsule<br />
                            a community broadcast<br />
                            a collective action<br />
                            a humming cycle<br />
                            a planetary commoning<br />
                            a long chain of events<br /></em>
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography
                        variant="body1">
                        This action will run for 21 years and requires your hums to keep it alive and growing. The action morphs over time and becomes more and more animated. It starts with a single hum, a sketch of locations, an open invitation that slowly forms a connective tissue. The hum is a gentle, musically democratic, and direct way to interact with both human and the more-than-human. Hums are utterances found in the voice of humans, machines, and non-human species, as well as sonic artefacts found within the botanical world, and planetary rotation. It is an utterance that challenges the logical isolation of the English language – it is pre-speech, subterranean, pre-linguistic, pre-enunciative and within it exists pure potential.                 </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sm={4}
                    justifyContent="center"
                >
                    <Typography
                        variant="body1">
                        <em>Calling all hummers</em>
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography
                        variant="body1">
                        The hum can be from your voice, or the voice of your fridge, dog, or thunderstorm. Why 21 years you might ask?

                        Honouring The Spirit of 21’ and an affirmative “f@$k off” to divisive narratives, together we are creating a real-time humming capsule full of curiosity and thick relational webs, as we head further into all sorts of changes. How will our humming shift over the next 21 years? How will technology develop? How will we retell stories of hum’s past?

                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography
                        variant="h5">
                        Instructions:
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sm={6}
                    justifyContent="center"
                >
                    <Typography
                        variant="body1"
                    >
                        1. {"  "}Record your hums onto a device.<br />
                        2. {"  "}Upload your hum audio file and enter your postcode and country.<br />
                        3. {"  "}Watch your hum enter the collective action.<br />
                        4. {"  "}You can isolate your hum and see how it connects to other hums.<br />
                        5. {"  "}Over time the hums will get longer and longer, more complex and evolved.<br />
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography
                        variant="body1">
                        Come back again and again and hum.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}